import { useState } from "react";
import { useNavigate } from "react-router";

function LoginScreen() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [grade, setGrade] = useState();

    function handleSubmit(e) {
      e.preventDefault();
      navigate(`/rooms/${grade}`)
    }

    return (
      <div className="w-screen h-screen bg-zinc-800 flex flex-col items-center content-center justify-center">
        <header className="text-center text-white">
          <h1 className="text-4xl p-3 font-mono">Привет!</h1>
          <p className='text-xl'>Чтобы войти, заполни поля ниже.</p>
        </header>
        <form className='p-5 flex flex-col w-fit my-5' onSubmit={handleSubmit}>
          <div>
          <label htmlFor='name' className='block px-5 py-1 font-medium text-lg text-white'>Твоё имя</label>
          <input type="text" value={name} onChange={e => setName(e.target.value)} id="name" className='rounded-lg'></input>
          </div>
  
          <div className='my-2'>
          <label htmlFor='cl' className='block px-5 py-1 font-medium text-lg text-white'>Твой класс</label>
          <input type="number" value={grade} onChange={e => setGrade(e.target.value)} id="cl" className='rounded-lg'></input>
          </div>
  
          <div className='my-6 text-center'>
          <button type="submit" className="bg-lime-300 hover:bg-lime-400 font-medium py-2 px-5 text-lg rounded-lg text-slate-800 shadow shadow-gray-600">Войти</button>
          </div>
        </form>
      </div>
    );
  }
  
  export default LoginScreen;
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ChatMessage(props) {
    const {gradeId} = useParams();

    return (
        <>
        <div className={"py-2 px-4 rounded-lg w-fit max-w-md m-2 " + 
                        (props.fromSelf ? "bg-gray-500 self-end" : "bg-violet-700 self-start")}
        >
            {props.children}
        </div>
        {props.suggestRoom !== undefined && <Link to={`/rooms/${gradeId}/${props.suggestRoom === null ? "" : props.suggestRoom}`} 
            className="bg-green-600 hover:bg-green-700 font-medium mx-2 py-2 w-fit px-3 text-md rounded-lg text-white shadow-xl"
        >
            Перейти
        </Link>}
      </>
    )
}
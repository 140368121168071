import { Link, useParams } from "react-router-dom";

export default function RoomCard( props ) {
    const { gradeId } = useParams();
    const colors = {
        "Комната теории": "bg-purple-700",
        "Комната помощи": "bg-teal-700",
        "Комната лайфхаков": "bg-yellow-700",
        "Комната друзей": "bg-pink-700",
        "Музыкальная математика": "bg-red-700",
    }

    return (
        <div className={"m-10 flex items-center content-center px-[10px] py-[30px] max-w-[200px] rounded-md shadow-md hover:shadow-lg hover:cursor-pointer font-semibold text-2xl text-center " + colors[props.name]}> 
            <Link to={`/rooms/${gradeId}/${props.id}`}>
                {props.name}
            </Link>
        </div>
    )
}
import Board from "./Board";
import Chat from "./Chat";

import data from "./data";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import drawing from "./assets/test_game.png";
import sum_1 from "./assets/sum_1.png";
import sum_1_res from "./assets/sum_1_res.png";
import sum_2 from "./assets/sum_2.png";
import sum_2_res from "./assets/sum_2_res.png";
import VideoRoom from "./VideoRoom";

export function DefaultRoom( props ) {
  const { gradeId, roomId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const roomData = data[gradeId].rooms[roomId];
  const drawings = {
    pal: drawing,
    sum_min: sum_1,
    sum_min_res: sum_1_res,
    sum_max: sum_2,
    sum_max_res: sum_2_res
  }

  const [message, setMessage] = useState("");
  const [boardImages, setBoardImages] = useState(<></>);
  const [boardContent, setBoardContent] = useState((<></>));
  const [currentIndex, setCurrentIndex] = useState({[roomId]: 0});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const action = roomData.actions[currentIndex[roomId]];
    setMessages(m => [{
      content: action.send,
      fromSelf: false
    }]);
    if (action?.boardSet) {
      setBoardContent((<>{action.boardSet}</>));
    } 
    setCurrentIndex(ind => {
      return { ...ind, [roomId]: ind[roomId] + 1 }
    });
  }, [roomId]);

  function handleSubmit(e) {
    e.preventDefault();
    let new_messages = [{
      content: message,
      fromSelf: true
    }];
    setMessage("");

    const action = roomData.actions[currentIndex[roomId]];
    if (action?.moveToRoom) {
      setCurrentIndex(ind => {
        return { ...ind, [action.moveToRoom.id]: action.moveToRoom.ind }
      });
      setBoardContent(<></>);
      setMessages([]);
      navigate(`/rooms/${gradeId}/${action.moveToRoom.id}`);
    }
    if (action?.send) {
      let msg = {
        content: action.send,
        fromSelf: false
      };
      if (action?.suggestRoom) {
        msg.suggestRoom = action.suggestRoom.id;
        setCurrentIndex(ind => {
          return { ...ind, [action.suggestRoom.id]: action.suggestRoom.ind }
        });
      }
      console.log(msg);
      new_messages.push(msg);
    }
    if (action?.boardAppend) {
      setBoardContent(content => (<>{content}<br />{action.boardAppend}</>));
    } 
    if (action?.boardSet) {
      setBoardContent((<>{action.boardSet}</>));
    } 
    if (action?.appendImage) {
      setBoardImages(images => (<><img src={drawings[action.appendImage]} className="max-w-[300px] h-auto"></img></>));
    }
    setMessages([...messages, ...new_messages]);
    setCurrentIndex(ind => {
      return { ...ind, [roomId]: ind[roomId] + 1 }
    });
  }
  
  return (
    <div className="text-white grid grid-cols-2 gap-y-2">
      <h1 className="col-span-2 text-4xl py-10 font-mono text-center h-fit">{roomData.name}</h1>
      <div className="mx-10 h-[32rem] overflow-auto flex flex-col-reverse">
        <Chat messages={messages}></Chat>
      </div>
      <Board>
        {boardContent}
        <div className="float-right flex flex-col items-end">{boardImages}</div>
      </Board>
      <form className="col-span-2 p-7 w-full flex justify-center" onSubmit={handleSubmit}>
        <input 
          type="text" 
          className="text-black rounded-lg border-none flex-1"
          value={message}
          onChange={e => setMessage(e.target.value)}
        ></input>
        <button 
          type="submit"
          className="bg-green-600 hover:bg-green-700 font-medium mx-2 py-2 px-3 text-lg rounded-lg text-white shadow-xl"
        >
          Отправить
        </button>
      </form>
    </div>
  );
}

export default function Room( props ) {
  const { roomId } = useParams();
  if ([2, 3, 4].includes(Number(roomId))) return <VideoRoom />
  else return <DefaultRoom />
}
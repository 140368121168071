import { useParams } from "react-router-dom";

import drawing from "./assets/greeting.png";
// import prec from "./assets/prec.mov";
import RoomCard from "./RoomCard";
import { Player } from "video-react";

export default function RoomList( props ) {
    const { gradeId } = useParams();
    return (
        <div className="text-white grid grid-cols-3 gap-y-2">
            <h1 className="col-span-3 text-4xl pt-10 pb-5 font-mono text-center h-fit">Знакомство</h1>
            <div className="mx-10 overflow-auto">
                <img src={drawing} className="w-96" />
                <div className="text-lg">Привет! Добро пожаловать в математический замок! Раз ты попал сюда, значит ты неравнодушен к математике, и я здесь для того, чтобы помочь тебе лучше в ней разобраться. Справа ты можешь увидеть список комнат в замке. Заходи в них, мы ждём тебя! Кстати, меня зовут Иван.</div>
            </div>
            <div className="flex mx-10 items-start flex-wrap flex-row col-span-2">
                <RoomCard name="Комната помощи" color="teal-700" id="0" />
                <RoomCard name="Комната теории" color="purple-700" id="1" />
                <RoomCard name="Комната лайфхаков" color="teal-700" id="2" />
                <RoomCard name="Комната друзей" color="teal-700" id="3" />
                {gradeId === "5" && <RoomCard name="Музыкальная математика" color="teal-700" id="4" />}
            </div>
        </div>
    )
}
import { useParams } from "react-router-dom";
import data from "./data";

import drawing from "./assets/greeting.png";
import VideoWidget from "./VideoWidget";

export default function VideoRoom( props ) {
    const { gradeId, roomId } = useParams();
    const roomData = data[gradeId].rooms[roomId];

    return (
        <div className="text-white grid grid-cols-3 gap-y-2">
            <h1 className="col-span-3 text-4xl pt-10 pb-5 font-mono text-center h-fit">{roomData.name}</h1>
            <div className="mx-10 overflow-auto">
                <img src={drawing} className="w-96" />
                <div className="text-lg">{roomData.desc}</div>
            </div>
            <div className="flex mx-10 items-start flex-wrap flex-row col-span-2">
                {roomData.videos.map(v => (
                    <VideoWidget name={v.name} link={v.link} previewLink={v.previewLink}></VideoWidget>
                ))}
            </div>
        </div>
    )
}
export default function VideoWidget( props ) {
    return (
        <div className="m-10 max-w-[250px] ">
            <a href={props.link}>
                <div className="flex items-center content-center overflow-hidden max-w-[250px] rounded-md shadow-md hover:shadow-lg hover:cursor-pointer font-semibold text-center"> 
                    <img src={props.previewLink}></img>
                </div>
            </a>
            <a href={props.link} className="py-2 text-center block">{props.name}</a>
        </div>
    );
}
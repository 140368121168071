import { Outlet } from "react-router-dom";

function App() {
  return (
    <div className="w-full h-full bg-zinc-800 overflow-hidden font-exo">
      <Outlet />
    </div>
  );
}

export default App;

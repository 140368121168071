import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'video-react/dist/video-react.css'; // import css

import App from './App';
import LoginScreen from "./LoginScreen";
import RoomList from "./RoomsList";
import Room from "./Room";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<LoginScreen />} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="rooms/:gradeId" element={<div><Outlet /></div>}>
          <Route index element={<RoomList />} />
          <Route path=":roomId" element={<Room />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

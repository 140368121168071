export default {
    5: {
        rooms: [
            {
                name: "Комната помощи",
                actions: [
                    {
                        send: "Приветствую! Ты попал в комнату помощи, и здесь у тебя специальная миссия. Согласен помогать мне в решении задач из жизни?"
                    },
                    {
                        send: "Поехали! Я как раз вспомнил, что недавно моя подруга Маша дала мне список продуктов с их весом. Я задумался: сможет ли пакет выдержать этот груз? Грузоподъёмность пакета — 6 кг. Поможешь посчитать?"
                    },
                    {
                        send: <>Список продуктов:<br />Помидоры — 0,7 кг<br />Огурцы — 0,4 кг<br />Лук — 0,65 кг<br />Картофель — 1,2 кг<br />Мандарины — 1,63 кг<br />Сыр — 0,80 кг<br />Морковь — 0,35 кг<br /><br />Что будем делать?</>
                    },
                    {
                        send: "Верно! Как называются эти числа и как они складываются?",
                        boardSet: "0,7 + 0,4 + 0,65 + 1,2 + 1,63 + 0,80 + 0,35 ="
                    },
                    {
                        send: "Не переживай! Давай перейдём в комнату теории и повторим это.",
                        suggestRoom: {
                            id: 1,
                            ind: 0
                        }
                    },
                    {
                        send: "Ну что, готов продолжать? Что сделаем?",
                        boardSet: "0,7 + 0,4 + 0,65 + 1,2 + 1,63 + 0,80 + 0,35 ="
                    },
                    {
                        send: "Верно! Не забываем про запятую. Что получится?",
                        appendImage: "sum_max"
                    },
                    {
                        send: "Отлично? Ну что, пакет не порвётся?",
                        boardSet: "0,7 + 0,4 + 0,65 + 1,2 + 1,63 + 0,80 + 0,35 = 5,73",
                        appendImage: "sum_max_res"
                    },
                    {
                        send: "Замечательно! Спасибо тебе за помощь! Можешь перейти на главную страницу и изучить другие комнаты.",
                        suggestRoom: {
                            id: null
                        }
                    }
                ]
            },
            {
                name: "Комната теории",
                actions: [
                    {
                        send: "Друг, сегодня я тебе расскажу, как складывать и вычитать десятичные дроби! Давай начнём. Что мы знаем о десятичной дроби?"
                    },
                    {
                        send: <>Отлично! Алгоритм сложения дробей следующий:<ol className="list-decimal px-5"><li>Уравнять кол-во знаков после запятой</li><li>В столбик записать все дроби, запятая над запятой, и выполнить сложение</li></ol>Понятно?</>
                    },
                    {
                        send: "Давай разберём пример. Посмотри на первый шаг алгоритма: что надо добавить в конец первой дроби?",
                        boardAppend: "2,3 + 16,08 = "
                    },
                    {
                        send: "Верно! Теперь запишем всё в столбик и сложим. Что получится?",
                        appendImage: "sum_min"
                    },
                    {
                        send: "Супер! Пойдём в комнату помощи?",
                        appendImage: "sum_min_res",
                        boardSet: "2,3 + 16,08 = 18,38",
                        suggestRoom: {
                            id: 0,
                            ind: 5
                        }
                    }
                ]
            },
            {
                name: "Комната лайфхаков",
                desc: "Приветствую тебя в комнате лайфхаков! Тут мои друзья расскажут тебе способы, с помощью которых можно быстро выполнять некоторые математические действия. Нажимай на видео справа!",
                videos: [
                    {
                        name: "Как легко возвести в квадрат число, оканчивающееся на 5",
                        link: "https://disk.yandex.ru/d/nZ58B-w5sfoDkQ/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%9A%D0%B0%D0%BA%20%D0%BB%D0%B5%D0%B3%D0%BA%D0%BE%20%D0%B2%D0%BE%D0%B7%D0%B2%D0%B5%D1%81%D1%82%D0%B8%20%D0%B2%20%D0%BA%D0%B2%D0%B0%D0%B4%D1%80%D0%B0%D1%82%20%D1%87%D0%B8%D1%81%D0%BB%D0%BE%2C%20%D0%BE%D0%BA%D0%B0%D0%BD%D1%87%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B5%D0%B5%D1%81%D1%8F%20%D0%BD%D0%B0%205.mov",
                        previewLink: "https://downloader.disk.yandex.ru/preview/ec9391724d1db199b1ea73a8ede1d422bd92064357f4e79ebbb9fa8aed4a1487/622e4194/N2nt-3-RSLFFL1YIKPe22LJTJ9t6OoxDSYpyX2-M8XxKlGvlT_0KqIqDFUGRndV0ZlMOxVsecU3ZO1pJZtsvhw%3D%3D?uid=0&filename=%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%9A%D0%B0%D0%BA%20%D0%BB%D0%B5%D0%B3%D0%BA%D0%BE%20%D0%B2%D0%BE%D0%B7%D0%B2%D0%B5%D1%81%D1%82%D0%B8%20%D0%B2%20%D0%BA%D0%B2%D0%B0%D0%B4%D1%80%D0%B0%D1%82%20%D1%87%D0%B8%D1%81%D0%BB%D0%BE%2C%20%D0%BE%D0%BA%D0%B0%D0%BD%D1%87%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B5%D0%B5%D1%81%D1%8F%20%D0%BD%D0%B0%205.mov&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=300x300"
                    },
                    {
                        name: "Как легко посчитать обыкновенные дроби",
                        link: "https://disk.yandex.ru/d/nZ58B-w5sfoDkQ/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%9A%D0%B0%D0%BA%20%D0%BB%D0%B5%D0%B3%D0%BA%D0%BE%20%D0%BF%D0%BE%D1%81%D1%87%D0%B8%D1%82%D0%B0%D1%82%D1%8C%20%D0%BE%D0%B1%D1%8B%D0%BA%D0%BD%D0%BE%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%B4%D1%80%D0%BE%D0%B1%D0%B8.mov",
                        previewLink: "https://downloader.disk.yandex.ru/preview/344c6d5a92637375d828925fc74d58b1048d5c8bf6c50f3772447aed119c8ab6/622e4194/cuaVZnGSLEemsTBOF4nwAh9BxfR9-mMv-6leHXcee6EXpp8Wznns2_cdHa4Fl2WmwX1CJ34rGIFnFJXccWiRYA%3D%3D?uid=0&filename=%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%9A%D0%B0%D0%BA%20%D0%BB%D0%B5%D0%B3%D0%BA%D0%BE%20%D0%BF%D0%BE%D1%81%D1%87%D0%B8%D1%82%D0%B0%D1%82%D1%8C%20%D0%BE%D0%B1%D1%8B%D0%BA%D0%BD%D0%BE%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5%20%D0%B4%D1%80%D0%BE%D0%B1%D0%B8.mov&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=300x300"
                    },
                    {
                        name: "Как легко умножать на девять",
                        link: "https://disk.yandex.ru/d/nZ58B-w5sfoDkQ/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%9A%D0%B0%D0%BA%20%D0%BB%D0%B5%D0%B3%D0%BA%D0%BE%20%D1%83%D0%BC%D0%BD%D0%BE%D0%B6%D0%B0%D1%82%D1%8C%20%D0%BD%D0%B0%20%D0%B4%D0%B5%D0%B2%D1%8F%D1%82%D1%8C.mov",
                        previewLink: "https://downloader.disk.yandex.ru/preview/8636cc42fcb5a1ac3e8673ddde73cae5a75da4a8357051fec90e859a031a2bf1/622e4194/meKTghxqEkJ8M2qJVT3PfeAdhu15b-rjMo48JMNGFtjcvfdZ_NkmS_IcPTgdyKnTRSmQOz6cdBup1EegRfKQ8A%3D%3D?uid=0&filename=%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%9A%D0%B0%D0%BA%20%D0%BB%D0%B5%D0%B3%D0%BA%D0%BE%20%D1%83%D0%BC%D0%BD%D0%BE%D0%B6%D0%B0%D1%82%D1%8C%20%D0%BD%D0%B0%20%D0%B4%D0%B5%D0%B2%D1%8F%D1%82%D1%8C.mov&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=300x300"
                    },
                    {
                        name: "Умножение двух последовательных чисел",
                        link: "https://disk.yandex.ru/d/nZ58B-w5sfoDkQ/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%A3%D0%BC%D0%BD%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B4%D0%B2%D1%83%D1%85%20%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B4%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%87%D0%B8%D1%81%D0%B5%D0%BB.mov",
                        previewLink: "https://downloader.disk.yandex.ru/preview/06e558c11de4cb2ab74f1e70488507d8ee842cdecaeb2afb68ea2da1dfd53aaf/622e4194/V7KJkVAgjc9Rp4T2B-44W_7pu3-S-GQduG5Eu-VluWLtwrVzXGXjbva2uzwyqiJ9F0vB-sxm23uG8nkMrGOB2g%3D%3D?uid=0&filename=%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%BB%D0%B0%D0%B9%D1%84%D1%85%D0%B0%D0%BA%D0%BE%D0%B2.%20%D0%A3%D0%BC%D0%BD%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B4%D0%B2%D1%83%D1%85%20%D0%BF%D0%BE%D1%81%D0%BB%D0%B5%D0%B4%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%87%D0%B8%D1%81%D0%B5%D0%BB.mov&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=300x300"
                    }
                ]
            },
            {
                name: "Комната друзей",
                desc: "Приветствую тебя в комнате друзей! Здесь мои друзья поделятся с тобой советами и разными интересными историями. Нажимай на видео справа!",
                videos: [
                    {
                        name: "Проценты",
                        link: "https://disk.yandex.ru/d/nZ58B-w5sfoDkQ/%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D1%80%D1%83%D0%B7%D0%B5%D0%B9.%20%D0%9F%D1%80%D0%BE%D1%86%D0%B5%D0%BD%D1%82%D1%8B.mov",
                        previewLink: "https://downloader.disk.yandex.ru/preview/15df75751ffd7c6f9c3884a827ec56318f7af6e202e990af95d60c825479d8a4/622e4194/tmSZupezb9mIdjUpdTo08ZSjrEqkK3xJkcVN7BzzsrR-85Ks_OYXbLye0nCvGaGAktcrYi_qpJscaAkV8fTn5w%3D%3D?uid=0&filename=%D0%9A%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0%20%D0%B4%D1%80%D1%83%D0%B7%D0%B5%D0%B9.%20%D0%9F%D1%80%D0%BE%D1%86%D0%B5%D0%BD%D1%82%D1%8B.mov&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=300x300",
                    },
                ]
            },
            {
                name: "Музыкальная математика",
                desc: "Приветствую! Эта комната необычная: в ней ты познакомишься с интересными способами представления различных математических методов, например, при помощи стихов. Нажимай на видео справа!",
                videos: [
                    {
                        name: "Пропорции — определение",
                        link: "https://disk.yandex.ru/d/nZ58B-w5sfoDkQ/%D0%9C%D1%83%D0%B7%D1%8B%D0%BA%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%BD%D0%B0%D1%8F%20%D0%BA%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0.%20%D0%9F%D1%80%D0%BE%D0%BF%D0%BE%D1%80%D1%86%D0%B8%D0%B8%20%D0%BE%D0%BF%D1%80%D0%B5%D0%B4%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5.mov",
                        previewLink: "https://downloader.disk.yandex.ru/preview/2b00792b6181a99a4b3bb8cfde5035ae7f2a1f26f8e9899a8feaa7ee3771afc6/622e4194/mKQAQCfKaMjY2hsBgrdpo6LQFWqu5xtmRvuEKazZ7oGpHWeU0PDg94t6TVkDwhEzRKMs6A1xxACxJcf4wNOIRw%3D%3D?uid=0&filename=%D0%9C%D1%83%D0%B7%D1%8B%D0%BA%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%BD%D0%B0%D1%8F%20%D0%BA%D0%BE%D0%BC%D0%BD%D0%B0%D1%82%D0%B0.%20%D0%9F%D1%80%D0%BE%D0%BF%D0%BE%D1%80%D1%86%D0%B8%D0%B8%20%D0%BE%D0%BF%D1%80%D0%B5%D0%B4%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5.mov&disposition=inline&hash=&limit=0&content_type=image%2Fjpeg&owner_uid=0&tknv=v2&size=300x300",
                    },
                ]
            }
        ]
    },
    6: {
        rooms: [
            {
                name: "Комната помощи",
                actions: [
                    {
                        send: "Привет. Ты не представляешь, что произошло со мной! Вчера вечером у меня на балансе стало -233 рубля. Теперь, чтобы выполнить звонок, мне нужно, чтобы на балансе оказался хотя бы один рубль. Мои друзья предолжили мне помощь: Петя погасит 30 рублей долга, а Даня и Виталя разобьют оставшуюся сумму пополам. Какую сумму заплатит Виталя? Поможешь мне это посчитать?"
                    },
                    {
                        send: "Что сделаем сначала?"
                    },
                    {
                        send: "Правильно, молодец! А что сделаем вторым действием?",
                        boardSet: "1) -233 - 1 = -234 (p.) необходимо для звонка"
                    },
                    {
                        send: "Супер! Нам осталось разделить число на два и ответ будет готов.",
                        boardAppend: "2) -234 + 30 = - (234 - 30) = -204 (p.) остаток после помощи Пети"
                    },
                    {
                        send: "Не переживай! Давай перейдём в комнату теории и рассмотрим, как это делается.",
                        suggestRoom: {
                            id: 1,
                            ind: 0
                        }
                    },
                    {
                        send: "Ну что? Сможешь получить ответ?"
                    },
                    {
                        send: "Класс! Спасибо тебе! Можешь перейти на главную страницу и изучить другие комнаты.",
                        suggestRoom: {
                            id: null
                        },
                        boardAppend: "3) -204 : 2 = -(204 : 2) = -102 (p.) должны заплатить Виталя и Даня"
                    }
                ]
            },
            {
                name: "Комната теории",
                actions: [
                    {
                        send: "Друг, сегодня мы поговорим о делении отрицательных чисел. Готов?"
                    },
                    {
                        send: "Рассмотрим первый случай: деление отрицательного числа на отрицательное. Для этого надо разделить модуль делимого на модуль делителя. Кстати, что такое модуль?"
                    },
                    {
                        send: "Это расстояние от начала отсчёта до точки на прямой. Расстояние всегда какое по знаку?"
                    },
                    {
                        send: "Молодец! Тогда какой знак будет у результата?"
                    },
                    {
                        send: "Отлично! Я написал пример на доске. Переходим к следующему случаю?",
                        boardAppend: "-216 : (-2) = |-216| : |-2| = 216 : 2 = 108"
                    },
                    {
                        send: "Рассмотрим второй случай: деление чисел с разными знаками. Мы точно так же делим модуль делимого на модуль делителя, но в конце ставим перед полученным числом знак минус. Есть вопросы?"
                    },
                    {
                        send: "Молодец, ты справился! Внимательно рассмотри пример и переходи в комнату помощи для практики!",
                        boardAppend: "(-144) : 12 = -(|-144| : |12|) = -(144 : 12) = -12",
                        suggestRoom: {
                            id: 0,
                            ind: 5
                        }
                    }
                ]
            }
        ]
    },
    10: {
        rooms: [
            {
                name: "Комната помощи",
                actions: [
                    {
                        send: "Приветствую! Ты попал в комнату помощи, и здесь у тебя специальная миссия. Согласен помогать мне в решении задач из жизни?"
                    },
                    {
                        send: "Поехали! Например, мне надо перевезти карниз в машине с размерами багажника a = 120 см, b = 90 см, c = 80 см. Поместится ли туда карниз длиной 200 см? Как мне это сделать?",
                        boardSet: (<>a = 120 см<br />b = 90 см<br />c = 80 см</>)
                    },
                    {
                        send: "На какое геометрическое тело похож багажник?"
                    },
                    {
                        send: "Не расстраивайся. Это прямоугольный параллелепипед. Давай вспомним его свойства. Какие свойства ты знаешь?"
                    },
                    {
                        send: "Ничего страшного. Давай перейдём в комнату теории и повторим свойства, которые можно применить их к нашей задаче!",
                        suggestRoom: {
                            id: 1,
                            ind: 0
                        }
                    },
                    {
                        send: "Поехали! По теореме: квадрат диагонали прямоугольного параллелепипеда равен сумме квадратов трёх его измерений. Тогда что?",
                        boardSet: (<>a = 120 см<br />b = 90 см<br />c = 80 см<br />d² = a² + b² + c²</>)
                    },
                    {
                        send: "Что получится?",
                        boardAppend: <>d² = 120² + 90² + 80²<br />d² = 1440 + 8100 + 6400</>
                    }, 
                    {
                        send: "Супер! Большое спасибо за помощь! Можешь перейти на главную страницу и изучить другие комнаты.",
                        suggestRoom: {
                            id: null
                        },
                        boardAppend: <>d² = 28900<br />d = 170</>
                    }
                ]
            },
            {
                name: "Комната теории",
                actions: [
                    {
                        send: "Сегодня мы поговорим о прямоугольном параллелепипеде. Но для начала вспомним, что такое параллелепипед?"
                    },
                    {
                        send: "Верно! Какие его свойства ты знаешь?"
                    },
                    {
                        send: "Ничего страшного. Напомню тебе замечательную теорему о прямоугольном параллелепипеде: квадрат его диагонали равен сумме квадратов трёх его измерений. Давай докажем этот факт."
                    },
                    {
                        send: "Что у нас дано?"
                    },
                    {
                        send: "Молодец правильно! Давай построим его. Где у нас диагональ?",
                        appendImage: "pal"
                    },
                    {
                        send: "Верно! Начнём доказательство. Что бы ты сделал сначала?",
                        boardAppend: (<>
                            ABCA₁B₁C₁ - прямоугольный параллелепипед<br />
                            B₁D - диагональ<br />
                            Док-ть, что B₁D² = AD² + DC² + DD₁²
                            </>),
                    },
                    {
                        send: (<>Проведём отрезок BD и рассмотрим треугольник B₁BD. Какой он?</>),
                    },
                    {
                        send: "Верно, прямоугольный! А почему?",
                        boardSet: "1) B₁BD — прямоуг."
                    },
                    {
                        send: "Т. к. из определения прямоугольного параллелепипеда: рёбра перпендикулярны плоскости основания. А какую теорему мы знаем?"
                    },
                    {
                        send: "Замечательно! Какую теорему мы можем применить? Помним, что наша цель — диагональ!"
                    },
                    {
                        send: "Отлично, но что мы знаем про BD?",
                        boardAppend: "2) B₁D² = BD² + BB₁²"
                    },
                    {
                        send: "Да, но для треугольника ABD BD — гипотенуза, поскольку ABD прямоугольный. Чему тогда равен BD²?"
                    },
                    {
                        send: "Да! Что мы можем заметить теперь?",
                        boardAppend: "3) BD² = AB² + AD²"
                    },
                    {
                        send: "Отлично! Что из этого следует?",
                        boardAppend: "3) B₁D² = AB² + AD² + BB₁²"
                    },
                    {
                        send: "Супер. Пошли в комнату помощи?",
                        suggestRoom: {
                            id: 0,
                            ind: 5
                        }
                    }
                ]
            }
        ]
    }
}
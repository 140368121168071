import ChatMessage from "./ChatMessage";

export default function Chat(props) {
    return (
        <div className="flex flex-col">
            {props.messages.map(msg => (
                <ChatMessage fromSelf={msg.fromSelf} suggestRoom={msg.suggestRoom}>{msg.content}</ChatMessage>
            ))}
        </div>
    );
}